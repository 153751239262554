import React, { useState, useEffect } from "react";

import { Container, Icon, TextInput, Select } from "../../../";

import css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button, Dropdown, Menu } from "antd";
import { connect } from "react-redux";

import CreditCards from "./credit-cards";

import axios from "axios";

import moment from "moment";

import { handleInputChange } from "../../../../utils";

import { rountingNumberInputHandler,ccNumberInputInputHandler } from "../../../../utils";

import Toastify from "toastify-js";

const Divider = () => <Container className={css.divider} />;

const CheckoutRaw = (props) => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [exp, setExp] = useState();
  const [cvc, setCvc] = useState();
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Bank/ACH Payment");
  const [accountType, setAccountType] = useState();
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [routingNumber, setRoutingNumber] = useState();
  const params = JSON.parse(localStorage.getItem("universal_params"));
  const spouse = props.applicants.spouse;

  const today = moment().startOf("day");

  const firstDraftDay = props.draftDay ? props.effectiveDate.clone().subtract(props.draftDay, "days") < today ? today : props.effectiveDate.clone().subtract(props.draftDay, "days") : props.effectiveDate.clone().subtract(props.draftDate ? props.drafDate : 1, "days").startOf("day") > today ? props.effectiveDate.clone().subtract(props.draftDate ? props.drafDate : 1, "days").startOf("day") : today;
  const onlyFeesToday = firstDraftDay > today;
  const recurringPaymentDate = firstDraftDay > today ? props.effectiveDate.clone().subtract(props.draftDay, "days") : props.effectiveDate.clone().subtract(props.draftDay, "days").add(1, "months");

  const p = JSON.parse(
    localStorage.getItem("universal_plans")
  )[0];

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.checkoutContainer}>
      <Container className={css.paymentInfoIconAndTitle}>
        <Container className={css.paymentInfoIcon}>
          <Icon icon="shopping-cart-circle" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          <Container>Payment Information:</Container>
          <Container className={css.paymentDisclaimer}>
              Please select a payment method:
          </Container>
        </Container>
      </Container>

      <Container className={css.inputs}>

        <Divider />
        <Container className={css.cardInputs}>
          <Container>
            <Container className={css.paymentMethodContainer}>
              <Dropdown
                visible={dropDownVisible}
                overlay={
                  <Menu>
            
                    <Menu.Item>
                      <Container
                      name="test-enrollment-enrollment-checkout-select-payment-bank"
                        onPress={() => {
                          setPaymentMethod("Bank/ACH Payment");
                          setDropDownVisible(false);
                        }}
                      >
                        Bank/ACH Payment
                      </Container>
                    </Menu.Item>
                    <Menu.Item>
                      <Container
                        onPress={() => {
                          setPaymentMethod("Credit Card");
                          setDropDownVisible(false);
                        }}
                      >
                        Credit Card
                      </Container>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Container
                  className={"payment-method-bug"}
                  onPress={() => {
                    setDropDownVisible(!dropDownVisible);
                  }}
                >
                  <Container className={css.method}>Method</Container>
                  <Container className={css.creditCard}>
                    {paymentMethod}
                    <Icon
                      className={css.chevronDownIcon}
                      fill={"#77C3FF"}
                      icon="chevron-down"
                    />
                  </Container>
                </Container>
              </Dropdown>
              <Container className={css.creditCards}>
                {paymentMethod == "Credit Card" && <CreditCards />}
                {paymentMethod != "Credit Card" && (
                  <Container
                    className={css.upcomingPaymentsInfoContainer}
                    style={{
                      transform: "translate(-48px, -6px",
                      height: "59px",
                    }}
                  >
                    <Container
                      style={{ marginRight: "24px" }}
                      className={css.n}
                    >
                      <Icon
                        style={{
                          width: "36px",
                          height: "36px",
                          transform: "translateY(2px",
                        }}
                        fill="#77c3ff"
                        icon="info-shield"
                      />
                    </Container>
                    <Container className={css.upcomingPaymentsTexts}>
                      <Container className={css.upcomingPaymentsText}>
                        Your first payment will be securely debited from your
                        bank account with the submission of your application
                      </Container>
                    </Container>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
          {paymentMethod == "Credit Card" && (
            <>
              <Container className={css.paymentInfoIconAndTitle}>
                <Container className={css.paymentInfoIcon}>
                  <Icon icon="credit-card-circle" />
                </Container>
                <Container className={css.additionalInfoTitle}>
                  <Container>Credit Card info:</Container>
                  <Container className={css.paymentDisclaimer}>
                      The total amount will include a 3.5% charge for the use of a credit card as your payment method.
                  </Container>
                </Container>
              </Container>
                <Container>
                  <Container className={css.paymentInfosContainer}>
                    <Container className={css.paymentInfoContainer}>
                      <Container className={css.paymentIcon}>
                        <Icon fill="#60cc73" icon="due-today" />
                      </Container>
                      <Container className={css.paymentRadioButtonTexts}>
                        <Container className={css.paymentRadioButtonTitle}>
                          Due today
                        </Container>
                        <Container className={css.paymentRadioButtonText}>
                          One time application fee { onlyFeesToday ? "" : (" + Monthly Value" )}
                        </Container>
                      </Container>
                      <Container className={css.paymentRadioButtonValue}>
                        ${(((onlyFeesToday ? 0 : props.enrollment.universal.monthlyValue) + 75) * 1.035).toFixed(2)}
                      </Container>
                    </Container>
                    <Container className={css.upcomingPaymentsInfoContainer}>
                      <Container className={css.upcomingPaymentsIcon}>
                        <Icon fill="#77c3ff" icon="upcoming-payments" />
                      </Container>
                      <Container className={css.upcomingPaymentsTexts}>
                        <Container className={css.upcomingPaymentsTitle}>
                          Monthly Payments
                        </Container>
                        <Container className={css.upcomingPaymentsText}>
                          Recurring payments starting from {recurringPaymentDate.format('MM/DD/YYYY')}
                        </Container>
                      </Container>
                      <Container className={css.upcomingPaymentsValue}>
                        ${(props.enrollment.universal.monthlyValue * 1.035).toFixed(2)}
                      </Container>
                    </Container>
                  </Container>
                </Container>
                <Divider/>
              <Container>
                <Container className={css.cardNames}>
                  <Container className={`${css.cardName} ${css.firstName}`}>
                    <TextInput
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      value={firstName}
                      before="First Name"
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                  <Container className={`${css.cardName} ${css.lastName}`}>
                    <TextInput
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      value={lastName}
                      before="Last Name"
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                </Container>
              </Container>

              <Container className={css.cardNumbers}>
                <Container className={css.cardNumber}>
                  <TextInput
                    onChange={(e) => {
                      if (e.target.value.length > 19) return e.target.value;

                      const cc = ccNumberInputInputHandler(e);
                      setCardNumber(cc);
                    }}
                    value={cardNumber}
                    before="Card Number"
                  />
                </Container>

                <Container className={css.cardDetails}>
                  <Container className={css.exp}>
                    <TextInput
                      onChange={(e) => {

                          if(e.target.value.length > 7) return;
                          
                          const divider = (e.target.value.length == 2 && e.target.value.length > exp?.length ? "/" : "");

                          setExp(handleInputChange(e) + divider);
                      }}
                      value={exp}
                      style={{ paddingLeft: "10px" }}
                      mask={new RegExp("(0[1-9]|1[0-2])(/)(2[0-9]{3})")}
                      placeholder="Expiration Date"
                      beforeWidth="60px"
                      focusedlabel={"MM/YYYY"}
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                  <Container className={css.cvc}>
                    <TextInput
                      value={cvc}
                      onChange={(e) => { if(e.target.value > 9999) return; setCvc(handleInputChange(e))} }
                      mask={new RegExp("([0-9]{3}[0-9]?)")}
                      type="number"
                      placeholder="CVC"
                    />
                  </Container>
                </Container>
              </Container>
            </>
          )}
          {paymentMethod != "Credit Card" && (
            <>
              <Container className={css.paymentInfoIconAndTitle}>
                <Container className={css.paymentInfoIcon}>
                  <Icon icon="bank-acc" />
                </Container>
                <Container className={css.additionalInfoTitle}>
                  <Container>Banking account info:</Container>
                  <Container className={css.paymentDisclaimer}>
                    Fields should be in accord to the banking account that will
                    be used for your payments.
                  </Container>
                </Container>
              </Container>
                <Container>
                  <Container className={css.paymentInfosContainer}>
                    <Container className={css.paymentInfoContainer}>
                      <Container className={css.paymentIcon}>
                        <Icon fill="#60cc73" icon="due-today" />
                      </Container>
                      <Container className={css.paymentRadioButtonTexts}>
                        <Container className={css.paymentRadioButtonTitle}>
                          Due Today
                        </Container>
                        <Container className={css.paymentRadioButtonText}>
                          One time application fee {onlyFeesToday ? "" : (" + First payment")}
                        </Container>
                      </Container>
                      <Container className={css.paymentRadioButtonValue}>
                        ${( (onlyFeesToday ? 0 : props.enrollment.universal.monthlyValue ) + 75).toFixed(2)}
                      </Container>
                    </Container>
                    <Container className={css.upcomingPaymentsInfoContainer}>
                      <Container className={css.upcomingPaymentsIcon}>
                        <Icon fill="#77c3ff" icon="upcoming-payments" />
                      </Container>
                      <Container className={css.upcomingPaymentsTexts}>
                        <Container className={css.upcomingPaymentsTitle}>
                          Monthly Payments
                        </Container>
                        <Container className={css.upcomingPaymentsText}>

                          Recurring payments starting from {recurringPaymentDate.format('MM/DD/YYYY')}
                        </Container>
                      </Container>
                      <Container className={css.upcomingPaymentsValue}>
                        ${props.enrollment.universal.monthlyValue.toFixed(2)}
                      </Container>
                    </Container>
                  </Container>
                </Container>
                <Divider />
              <Container>
                <Container className={css.cardNames}>
                  <Container className={`${css.cardName} ${css.firstName} `}>
                    <TextInput
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      value={firstName}
                      before="First Name"
                    />
                  </Container>
                  <Container className={`${css.cardName} ${css.lastName}`}>
                    <TextInput
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      value={lastName}
                      before="Last Name"
                    />
                  </Container>
                </Container>
              </Container>

              <Container className={css.cardNames}>
                <Container className={`${css.cardName} ${css.firstName} `}>
                  <Select
                    value={accountType}
                    size="large"
                    before="Account type"
                    onChange={(e) => {
                      setAccountType(e);
                    }}
                    options={[
                      {
                        label: "Checking",
                        value: "Checking",
                      },
                      {
                        label: "Savings",
                        value: "Savings",
                      },
                    ]}
                  />
                </Container>
                <Container className={`${css.cardName} ${css.lastName} `}>
                  <TextInput
                    onChange={(e) => {
                      setBankName(handleInputChange(e));
                    }}
                    value={bankName}
                    before="Bank Name"
                  />
                </Container>
              </Container>

              <Container className={css.cardNames}>
                <Container className={`${css.cardName} ${css.firstName} `}>
                  <TextInput
                    onChange={(e) => {
                      setAccountNumber(handleInputChange(e));
                    }}
                    value={accountNumber}
                    before="Account Number"
                    mask={new RegExp("[0-9]{4,16}")}
                  />
                </Container>
                <Container className={`${css.cardName} ${css.lastName}`}>
                  <TextInput
                    value={routingNumber}
                    onChange={(e) => setRoutingNumber( rountingNumberInputHandler(e))}
                    before="Routing Number"
                    mask={new RegExp("[0-9]{3} [0-9]{3} [0-9]{3}")}
                  />
                </Container>
              </Container>
            </>
          )}
        </Container>
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
              onClick={async () => {
                setLoading(true);
                try {
                  const resp = await axios({
                    method: "post",
                    url: `${props.baseUrl}/v1/enrollment/universal`,
                    data: {
                      ...{
                        agent: { 
                          first_name: params.agentFirstName,
                          last_name: params.agentLastName,
                          email: params.agentEmail,
                          broker_id: params.brokerId
                        },
                        location: {
                          zip_code:params.zip_code,
                          state: params.state,
                          city: props.city
                        },
                        quote_id: params.quote_id,
                        primary_applicant: {
                          age: params.age,
                          first_name: params.firstName || "John",
                          last_name: params.lastName || "Doe",
                          gender: params.gender,
                          date_of_birth: props.date_of_birth.toDate()
                            .toISOString()
                            .split("T")[0],
                          uses_tobacco: params.uses_tobacco,
                          phone: params.phone,
                          email: params.email,
                        },
                        spouse: spouse
                          ? {
                            first_name: props.spouse.first_name,
                            last_name: props.spouse.last_name,
                            age: JSON.parse(JSON.parse(params.spouse))
                              .age,
                            gender: JSON.parse(JSON.parse(params.spouse))
                              .gender,
                            date_of_birth: props.spouse.date_of_birth
                              .toDate()
                              .toISOString()
                              .split("T")[0],
                            uses_tobacco: props.spouse.uses_tobacco || false,
                          }
                          : undefined,
                        dependents: props.dependents
                          ? props.dependents.map((d, ind) => {
                            return {
                              age: d.age,
                              first_name: d.first_name,
                              last_name: d.last_name,
                              gender: d.gender || "Female",
                              date_of_birth: d.date_of_birth
                                .toDate()
                                .toISOString()
                                .split("T")[0],
                              uses_tobacco: d.uses_tobacco || false,
                            };
                          })
                          : [],
                        payment:
                          paymentMethod != "Credit Card"
                            ? {
                              first_name: firstName,
                              last_name: lastName,
                              bank_name: bankName, // Chase Bank
                              routing_number: routingNumber.split(" ").join(""), //044000037
                              account_number: accountNumber, // 1234567890
                              account_type: accountType || "Checking",
                           
                            }
                            : {
                              first_name: firstName || "John",
                              last_name: lastName || "Doe",
                              credit_card_number:
                                cardNumber || "4112 3441 1234 4113",
                              expiration_date: exp || "12/2025",
                              cvc: cvc + "" || "123",
                              ...(props.firstPaymentDate
                                ? {
                                  first_payment_date: props.firstPaymentDate
                                    .toDate()
                                    .toISOString()
                                    .split("T")[0],
                                }
                                : {}),
                            },
                        selected_plan:{
                            plan_id: p.plan_id.value + "",
                            effective_date: (
                              props.effectiveDate
                            )
                              .toDate()
                              .toISOString()
                              .split("T")[0],
                          monthly_payment: p.plan_only_premium.value,
                          draft_day: props.draftDay ?? props.draftDay
                          },
                      },
                    },
                    headers: {
                      "Apollo-Quotes-Source": props.agentMetadata.id,
                    },
                  });
                  setLoading(false);

                  if (Number(resp.status) == 200) {
                    props.setAdditionalInfo({
                      key: "memberPortalUrl",
                      value: resp.data.memberPortalUrl,
                    });
                    props.setCurrentStep(3);
                  } 
                  else if (Number(resp.status) == 208){
                    Toastify({
                      text:
                      "Email already registered in the Universal system, please contact your agent",
                      duration: 10000,
                      newWindow: true,
                      close: true,
                      gravity: "bottom",
                      position: "center",
                      backgroundColor: "#D33F49",
                      stopOnFocus: true,
                      className: "notification",
                    }).showToast();
                  }
                  else {
                    Toastify({
                      text:
                        "We are sorry, we couldn't complete your application, please try again in a few minutes.",
                      duration: 10000,
                      newWindow: true,
                      close: true,
                      gravity: "bottom",
                      position: "center",
                      backgroundColor: "#D33F49",
                      stopOnFocus: true,
                      className: "notification",
                    }).showToast();
                  }
                 
                }
                catch (ex) {
                  setLoading(false);
                  Toastify({
                    text:
                      "We are sorry, we couldn't complete your application, please try again in a few minutes.",
                    duration: 10000,
                    newWindow: true,
                    close: true,
                    gravity: "bottom",
                    position: "center",
                    backgroundColor: "#D33F49",
                    stopOnFocus: true,
                    className: "notification",
                  }).showToast();
                }
               
              }}
              type="primary"
              shape="round"
              disabled={
                paymentMethod == "Credit Card"
                  ? !firstName || !lastName || !cardNumber || !cvc || !exp
                  : !firstName ||
                  !lastName ||
                  !accountType ||
                  !accountNumber ||
                  !routingNumber
              }
          >
            Confirm Payment
          </Button>
        </Container>
      </Container>
      <Container className={css.disclaimerContainer}>
        The accountholder of the bank account of credit card provider during
        this enrollment process and requests the insurer to initiate automatic
        electronic payments against such indicated bank <br /> account of credit
        card for the payment of premiuns and other indicated monthly dues
        included in the plan(s) being purchased during this enrollment process.
        Accountholder agrees that the <br />
        electronic payment authorization for such automatic payments may be
        terminated by providing written notice to the insurer.
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    baseUrl,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.universal.additionalInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO_UNIVERSAL`, payload }),
    confirmApplication: (payload) =>
      dispatch({ type: `SET_APPLICATION_URL_UNIVERSAL`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutRaw);
